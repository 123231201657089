<style scoped>
	.main {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
</style>
<template>
	<div class="main" v-if="doubleDateList">
		<timeRuler v-if="doubleDateList.today" :oneDateList="doubleDateList.today" :styleConf="styleConf"></timeRuler>
		<timeRuler v-if="doubleDateList.nextday" style="margin-top: 10upx;" :oneDateList="doubleDateList.nextday" :styleConf="styleConf"></timeRuler>
	</div>
</template>


<script>
	
	import timeRuler from "./timeRuler.vue"
	
	export default {
		components:{
			timeRuler
		},
		name:"doubleTimeRuler",
		props:{
			doubleDateList:{
				type: Object,
				required: true
			},
			styleConf:{
				type:Object,
				required: true
			}
		},
		data() {
			return {
				/*
				styleConf:{
					radius: 10,  // 父容器圆角值  upx
					itemH: 20,  //  元素高度  upx
					un_order_color:'#6699ff',  // 不可预约颜色
					order_color: '#DCDCDC',  // 允许预约颜色
					bg_color:'#6699ff', // 默认底色
					textSize: 16  //  文字大小  upx
				},
				doubleDateList:{
					"today":{
							"dateList":[
								{
									'start_time': '00:00',
									'ended_time': '09:30',
									'css_pos':0,
									'css_width':40,
									'status':99
								},
								{
									'start_time': '12:10',
									'ended_time': '16:10',
									'css_pos':41,
									'css_width':3,
									'status':1
								},{
									'start_time': '21:10',
									'ended_time': '23:59',
									'css_pos':47,
									'css_width':9,
									'status':0
								},
							],
						"dateTag":"今日",
					},
					"nextday":{
							"dateList":[
								{
									'start_time': '00:00',
									'ended_time': '09:30',
									'css_pos':0,
									'css_width':40,
									'status':99
								},
								{
									'start_time': '12:10',
									'ended_time': '16:10',
									'css_pos':41,
									'css_width':3,
									'status':1
								},{
									'start_time': '21:10',
									'ended_time': '23:59',
									'css_pos':47,
									'css_width':9,
									'status':0
								},
							],
						"dateTag":"次日",
					}
				}
				*/
			};
		},
		mounted() {
		}
	}
</script>
