<template>
  <el-container class="body">
	  <canvas ref="myCanvas" style="position: fixed; left: 0; top: 0; z-index: 0;"></canvas>
      <div class="content" style="z-index: 1;">
      	<div class="login-box">
      		<div class="login-left">
      			<div class="logo">易达星辰</div>
      			<div class="logo-header">后台管理系统</div>
      		</div>
      		<div class="login-right">
				<el-input class="input" v-model="username" placeholder="账号" clearable></el-input>
				<el-input class="input" v-model="password" placeholder="密码" show-password clearable></el-input>
      			<el-checkbox class="input" v-model="remember">记住密码</el-checkbox>
				<el-button class="input" type="primary" @click="login()">立即登录</el-button>
      		</div>
      	</div>
      </div>
  </el-container>
</template>

<script>
export default {
	data() {
		return {
			username: '',
			password: '',
			remember: false,
			canvas:null,
			context:null,
			star_count:600,
			stars:[],
			AnimateId:null,
		};
	},
	mounted() {
		console.log("mounted");
		this.canvas = this.$refs.myCanvas;
		this.context = this.canvas.getContext("2d");
		this.canvas.width = window.innerWidth;
		this.canvas.height = window.innerHeight;
		
		this.$nextTick(() => {
			let remember = localStorage.getItem("rememberLogin");
			if(remember == 1)
			{
				this.username = localStorage.getItem("username");
				this.password = localStorage.getItem("password");
				this.remember = true;
			}
		});
		this.init_animate();
	},
	methods: {
		init_animate(){
			for(let i = 0; i < this.star_count; i++)
			{
				let y = Math.random() * this.canvas.height;
				let x = Math.random() * this.canvas.width;
				let size = Math.floor(Math.random() * 5);
				let speed = size / 7;
				this.stars.push({x:x, y:y, size:size, speed:speed});
			}
			this.animate();
		},
		animate(){
			this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
			for(let i = 0; i < this.star_count; i++)
			{
				let star = this.stars[i];
				this.context.beginPath();
				this.context.arc(star.x,  star.y , star.size, 0, Math.PI*2);
				this.context.fillStyle = 'white';
				this.context.fill();
				star.y -= star.speed;
				star.x += star.speed;
				if(star.y < 0) star.y = this.canvas.height;
				if(star.x > this.canvas.width) star.x = 0;
			}
			this.AnimateId = window.requestAnimationFrame(() => {
				this.animate();
			});
		},
		remembers(){
			if(this.remember === true)
			{
				localStorage.setItem("rememberLogin", 1);
				localStorage.setItem("username", this.username);
				localStorage.setItem("password", this.password);
			}else{
				localStorage.setItem("rememberLogin", 0);
				localStorage.removeItem("username");
				localStorage.removeItem("password");
			}
		},
		login() {
			let that = this;
			if(that.username.length < 5 || that.password.length < 5)
			{
				return that.$message({
					 message: '请输入正确的账号密码哟~',
					 type: 'warning',
				});
			}
			
			
			const loading = this.$loading({
					lock: true,
					text: '登录中',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			that.$api.post("/Login/LoginApi", {username:that.username, password:that.password}).then(res=>{
				if(res.code == 1)
				{
					that.$store.commit("loginSuccess", res.data);
					that.remembers();
					that.$message({
						 message: '登录成功~',
						 type: 'success',
					});
					setTimeout(()=>{
						window.cancelAnimationFrame(this.AnimateId);
						that.$router.push("/Dashboard");
					}, 2000);
				}else{
					return that.$message({
						 message: res.msg,
						 type: 'warning',
					});
				}
				loading.close();
			}).catch(err=>{
				loading.close();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
	
	@function getShadows($n){
		$shadows: '#{random(100)}vw #{random(100)}vh #fff';
		@for $i from 2 through $n {
			$shadows: '#{$shadows},#{random(100)}vw #{random(100)}vh #fff';
		}
		@return unquote($shadows);
	}
	
	@keyframes moveup{
		to{
			transform: translateY(-100vh);
		}
	}
	$duration: 2000s;
	$count: 1000;
	@for $i  from 1 through 5 {
		.layer#{$i} {
			transform: translateZ(0);
			$duration: floor($duration / 2);
			$count: floor($count / 2);
			position: fixed;
			$size: #{$i}px;
			width: $size;
			height: $size;
			background-color: white;
			border-radius: 50%;
			box-shadow:getShadows($count);
			animation: moveup $duration linear infinite;
			&::after {
				content:'';
				position: fixed;
				width: inherit;
				height: inherit;
				left: 0;
				top: 100vh;
				border-radius: inherit;
				box-shadow: inherit;
			}
		}
	}  
	
	
	.body {
		margin:0 ;
		/* background-image: url(/src/assets/bg3.jpg); */
		background-color: #000000;
		width: 100%;
		background-size: cover;
		background-position: 50%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		height: 100vh;
		overflow: auto;
	}

	.content {
		width: 100%; height: 100vh; display: flex; flex-direction: column; align-items: center;
	}
	.login-box {
		width: 600px; height: 300px; margin-top: 300px; display: flex; flex-direction: row;
		border-radius: 20px;
		box-shadow: 0px 0px 10px #000000;
		overflow: hidden;
		border: solid 1px white;
	}
	.login-left {
		width: 300px;
		opacity: 0.9;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
	}

	.logo {
		height: 60px;
		line-height: 60px;
		color: white;
		font-size: 28px;
		width: 240px;
		text-align: left;
		text-indent: 20px;
		border-bottom: dashed 1px white;
	}

	.logo-header {
		height: 60px;
		line-height: 60px;
		color: white;
		font-size: 20px;
		color: #ffffff;
		width: 240px;
		text-align: left;
		text-indent: 20px;
	}

	.login-right {
		background-color: rgba(255, 255, 255, 0.1);
		box-shadow: 0px 0px 5px #000000;

		width: 300px;

		align-items: center;
		justify-content: center;
		font-size: 20px;
		color: #999999;
		display: flex;
		flex-direction: column;
	}
	
	.input {
		margin-bottom: 15px;
		width: 200px;
	}
	.btn {
		opacity: 1;
		width: 180px;
		height: 30px;
		border:0;
		border-radius: 6px;
		background-color: #3399ff;
		color: white;
		font-size: 12px;
		box-shadow: 0px 0px 10px #000000;
	}


	.remember_box {
		width: 180px;
		margin-bottom: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.remember {
		text-indent: 5px;
		font-size: 12px;
		color: #333333;
	}

	input[type=checkbox] {
	  width: 18px;
	  height: 18px;
	}
</style>
