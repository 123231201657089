<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; justify-content: space-between; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<div style="display: flex; flex-direction: row; align-items: center;">
				<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item>系统设置</el-breadcrumb-item>
				  <el-breadcrumb-item>易微联设置</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="body_content">
			<div style="background-color: white; padding: 10px; border-radius: 10px; ">
				<el-form ref="form" :model="form" label-width="180px" style="width: 700px;" >
					<el-form-item label="AppId">
						<el-input v-model="form.appid"></el-input>
					</el-form-item>
					<el-form-item label="Secret">
						<el-input v-model="form.secret"></el-input>
					</el-form-item>
					<el-form-item label="授权状态">
						<el-switch v-model="form.status" disabled></el-switch>
					</el-form-item>
					<el-form-item label="accessToken">
						<el-input v-model="form.access_token" disabled></el-input>
					</el-form-item>
					<el-form-item label="accessToken有效期">
						<el-input v-model="form.token_expire" disabled></el-input>
					</el-form-item>
					<el-form-item label="freshToken">
						<el-input v-model="form.fresh_token" disabled></el-input>
					</el-form-item>
					<el-form-item label="freshToken有效期">
						<el-input v-model="form.fresh_expire" disabled></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="save">保存设置</el-button>
						<el-button type="danger" @click="getAuthUrl">点击授权</el-button>
						<el-button type="warning" @click="freshToken">刷新Token</el-button>
						<el-button type="info" @click="getDevices">查看设备列表</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import doubleTimeRuler from "@/components/other/doubleTimeRuler";
	import createOrder from "@/components/Order/createOrder";
	
	export default {
		name:'storeList',
		components:{
			doubleTimeRuler,
			createOrder
		},
		data(){
			return {
				form:{
					status:true
				}
			}
		},
		mounted() {
			this.getData();
		},
		methods:{
			getDevices(){
				let that = this;
				let loading = this.$loading({
						lock: true,
						text: '请求中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/System/getDevicesList", {}).then(res=>{
					if(res.code == 1)
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			},
			freshToken(){
				let that = this;
				let loading = this.$loading({
						lock: true,
						text: '请求中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/System/freshEwelinkToken", {}).then(res=>{
					if(res.code == 1)
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			},
			getAuthUrl(){
				let that = this;
				let loading = this.$loading({
						lock: true,
						text: '请求中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/System/getEwelinkAuthUrl", {}).then(res=>{
					if(res.code == 1)
					{
						window.open(res.data.url);
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			},
			refresh(){
				this.getData();
			},
			save(){
				let that = this;
				let loading = this.$loading({
						lock: true,
						text: '保存中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/System/saveEwelinkConfig", {appid:that.form.appid, secret:that.form.secret}).then(res=>{
					if(res.code == 1)
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			},
			getData(){
				let that = this;
				let loading = this.$loading({
						lock: true,
						text: '获取数据中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/System/getEwelinkConfig", {}).then(res=>{
					if(res.code == 1)
					{
						that.form = res.data.config;
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			}
		}
	}
</script>

<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	.body_content {
		padding: 10px;
	}

</style>