<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>订单管理</el-breadcrumb-item>
			  <el-breadcrumb-item>创建订单</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		
		<div class="body_content" v-for="(store, index) in list" :key="index">
			<div class="header">{{store.store_name}}</div>
			<div class="content">
				<div class="room_box" v-for="(room, ind) in store.room_list" @click="book(store,room)">
					<div class="room_line">
						<div class="room_img_box">
							<img class="room_img" src="../../assets/bg3.jpg"/>
						</div>
						<div class="room_info">
							<div class="f">{{room.room_name}}</div>
							<div class="f" style="color: #888888;">{{store.short_name}}</div>
							<div class="f">
								<el-button size="mini" type="primary" v-if="room.room_status==0">空闲</el-button>
								<el-button size="mini" type="danger" v-if="room.room_status==1">使用中</el-button>
								<el-button size="mini" type="warning" v-if="room.room_status==2">待保洁</el-button>
							</div>
						</div>
					</div>
					<doubleTimeRuler style="width: 100%;" :styleConf="styleConf" :doubleDateList="room.dateList"></doubleTimeRuler>
				</div>
			</div>
		</div>
		
		<el-dialog :title="creteOrderTitle" :visible.sync="createOrderView" width="700px" @close="createOrderViewClose">
			<createOrder v-if="createOrderView" :roomInfo="roomInfo" :storeInfo="storeInfo"></createOrder>
		</el-dialog>
	</div>
</template>

<script>
	import doubleTimeRuler from "@/components/other/doubleTimeRuler";
	import createOrder from "@/components/Order/createOrder";
	
	export default {
		name:'preCreateOrder',
		components:{
			doubleTimeRuler,
			createOrder
		},
		data(){
			return {
				creteOrderTitle:'创建订单',
				createOrderView:false,
				store_id:"",
				list:[],
				styleConf:{
					radius: 10,  // 父容器圆角值  upx
					itemH: 20,  //  元素高度  upx
					un_order_color:'#6699ff',  // 不可预约颜色
					order_color: '#DCDCDC',  // 允许预约颜色
					bg_color:'#6699ff', // 默认底色
					textSize: 16  //  文字大小  upx
				},
				roomInfo:{
					room_name:'',
				},
				storeInfo:{
					store_name:'',
				}
			}
		},
		created() {
			this.getData();
		},
		methods:{
			createOrderViewClose(){
				
			},
			book(store,room){
				this.creteOrderTitle = room.room_name + "       " + store.store_name + " ";
				this.roomInfo.room_name = room.room_name;
				this.roomInfo.room_id = room.room_id;
				this.roomInfo.hour_fee = room.hour_fee;
				this.storeInfo.store_name = store.store_name;
				this.createOrderView = true;
			},
			refresh(){
				this.getData();
			},
			getData(){
				let that = this;
				const loading = this.$loading({
						lock: true,
						text: '拼命刷新中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				that.$api.post("/Room/getPreBookList", {store_id:that.store_id}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					that.list = res.data.list;
				}).catch(res=>{
					loading.close()
				});
			}
		}
	}
</script>

<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.content {
		background-color: white;
		padding: 0 10px 10px;
		border-radius: 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	
	.room_box {
		display: flex;
		flex-direction: column;
		width: calc(20% - 30px);
		min-width: 260px;
		background-color: #ECECEC;
		padding: 10px;
		margin: 5px;
		border-radius: 5px;
	}
	.room_line {
		height: 110px;
		display: flex;
		flex-direction: row;
	}
	.room_img_box {
		width: 50%;
		height: 90px;
	}
	.room_img {
		width: 100%;
		height: 100%;
		border-radius: 5px;
	}
	
	.body_content {
		padding: 10px;
	}
	.header {
		height: 30px;
		line-height: 30px;
		font-size: 14px;
		color: #888888;
		background-color: white;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		text-indent: 20px;
	}
	.room_info {
		width: 50%;
		padding-left: 10px;
	}
	.f {
		width: 100%;
		line-height: 30px;
		font-size: 12px;
	}
</style>