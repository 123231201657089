<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; justify-content: space-between; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<div style="display: flex; flex-direction: row; align-items: center;">
				<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item>系统设置</el-breadcrumb-item>
				  <el-breadcrumb-item>设备列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="body_content">
			<div class='dev_line header'>
				<div class="dev_ui">设备类别</div>
				<div class="dev_name">设备名称</div>
				<div class="dev_id">设备ID</div>
				<div class="dev_id">联网状态</div>
				<div class="dev_id">开关状态</div>
				<div class="dev_id">品牌</div>
				<div class="dev_id">roomid</div>
			</div>
			<div v-for="(room, index) in devicesList" :key="index">
				<div class="dev_line" v-for="(dev, indexs) in room" :key="indexs">
					<div class="dev_ui">{{dev.ui}}</div>
					<div class="dev_name">{{dev.name}}</div>
					<div class="dev_id">{{dev.deviceid}}</div>
					<div class="dev_id">
						<el-switch v-model="dev.online" disabled :active-value="true" :inactive-value="false"></el-switch>
					</div>
					<div class="dev_id">
						<el-switch v-model="dev.params" active-value="on" inactive-value="off" @change="changeSwitch(index)"></el-switch>
					</div>
					<div class="dev_id">{{dev.brandName}}</div>
					<div class="dev_id">{{dev.roomid}}</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'devicesList',
		data(){
			return {
				devicesList:[]
			}
		},
		mounted() {
			this.getDevices();
		},
		methods:{
			changeSwitch(index){
				let dev_id = this.devicesList[index].deviceid;
				let status = this.devicesList[index].params;
				
				let that = this;
				let loading = this.$loading({
						lock: true,
						text: '请求中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/System/changeDevicesStatus", {device_id:dev_id, status:status}).then(res=>{
					if(res.code == 1)
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.devicesList[index].params = !status;
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					that.devicesList[index].params = !status;
					loading.close()
				});
			},
			refresh(){
				this.getDevices();
			},
			getDevices(){
				let that = this;
				let loading = this.$loading({
						lock: true,
						text: '请求中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/System/getDevicesList", {}).then(res=>{
					if(res.code == 1)
					{
						const groups = res.data.list.reduce((acc, cur) => ({
						  ...acc,
						  [cur.roomid]: [...(acc[cur.roomid] || []), cur]
						}), {});
						
						that.devicesList = groups;
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			}
		}
	}
</script>

<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	.body_content {
		padding: 10px;
	}

	.dev_line {
		background-color: white;
		margin-bottom: 1px;
		padding: 18px;
		font-size: 14px;
		border-radius: 4px;
		display: flex;
		flex-direction: row;
	}
	
	.dev_ui {
		width: 130px;
		text-align: center;
	}
	
	.dev_name {
		width: 300px;
		text-align: center;
	}
	
	.dev_id {
		width: 160px;
		text-align: center;
	}
	
	.header {
		font-weight: bold;
	}
</style>