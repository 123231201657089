import Vue from 'vue'
import Router from 'vue-router'
import MainFrame from '@/components/MainFrame'
import Login from '@/components/Login'

//数据面板
import Dashboard from '@/components/Dashboard/Dashboard'
//用户管理
import UserList from '@/components/User/userList'

import OrderList from '@/components/Order/orderList'
import preCreateOrder from '@/components/Order/preCreateOrder'
import createOrder from '@/components/Order/createOrder'

// 房间管理
import roomList from '@/components/Store/roomList'
import storeList from '@/components/Store/storeList'

// 管理员管理
import managerList from '@/components/Manager/managerList'

// 权限管理
import permissionList from '@/components/Permission/permissionList'
import roleList from '@/components/Permission/roleList'

// 系统设置
import eWelink from '@/components/System/eWelink'
import devicesList from '@/components/System/devicesList'
import meiTuan from '@/components/System/meiTuan'

import App from '@/App.vue'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    // 处理错误 跳转到重复路径上，忽略错误
    // console.error(err);
  });
};

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'App',
      component: App,
	  children:[
		  {
			path:'',
			component: MainFrame,
		  },
		  {
			name: 'Home',
			path: 'Home',
			component: MainFrame,
			children:[
				{
					path:'/Dashboard',
					name:'Dashboard',
					component:Dashboard
				},{
					path:'/UserList',
					name:'UserList',
					component:UserList
				},{
					path:'/OrderList',
					name:'OrderList',
					component:OrderList
				},{
					path:'/Order/createOrder',
					name:'createOrder',
					component:createOrder
				},{
					path:'/Order/preCreateOrder',
					name:'preCreateOrder',
					component:preCreateOrder
				},{
					path:'/Store/storeList',
					name:'storeList',
					component:storeList
				},{
					path:'/Store/roomList',
					name:'roomList',
					component:roomList
				},{
					path:'/Permission/roleList',
					name:'roleList',
					component:roleList
				},{
					path:'/Permission/permissionList',
					name:'permissionList',
					component:permissionList
				},{
					path:'/Manager/managerList',
					name:'managerList',
					component:managerList
				},{
					path:'/System/eWelink',
					name:'eWelink',
					component:eWelink
				},{
					path:'/System/devicesList',
					name:'devicesList',
					component:devicesList
				},{
					path:'/System/meiTuan',
					name:'meiTuan',
					component:meiTuan
				}
			]
		  }
	  ]
    },{
      path: '/Login',
      name: 'Login',
      component: Login
    }
  ]
})
