<style scoped>
	.row {
		display: flex;
		flex-direction: row;
		height: 50px;
		line-height: 50px;
		font-size: 14px;
	}
	.ckey {
		margin-left: 180px;
		width: 80px;
		text-align: right;
	}
	.btn {
		margin-bottom: 10px;
	}
</style>

<template>
	<div id="app" v-if="orderInfo">
		<div style="display: flex; flex-direction: column; margin-bottom: 30px;">
			<div class="row">
				<div class="ckey">门店：</div>
				<div class="cvalue">{{orderInfo.store_name}}</div>
			</div>
			<div class="row">
				<div class="ckey">包间：</div>
				<div class="cvalue">{{orderInfo.room_name}}</div>
			</div>
			<div class="row">
				<div class="ckey">时长：</div>
				<div class="cvalue">{{orderInfo.hour}} 小时</div>
			</div>
			<div class="row">
				<div class="ckey">开始时间：</div>
				<div class="cvalue" style="display: flex; flex-direction: row; align-items: center;">
					<el-date-picker v-model="orderInfo.start_time" :disabled="!editStatus" type="datetime" value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm"></el-date-picker>
					<el-button v-if="editStatus" @click="cancelEdit" type="info" round size="mini" style="margin-left: 20px;">取消修改</el-button>
				</div>
			</div>
			<div class="row">
				<div class="ckey">结束时间：</div>
				<div class="cvalue" style="display: flex; flex-direction: row; align-items: center;">
					<el-date-picker v-model="orderInfo.ended_time" :disabled="!editStatus"  type="datetime" value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm"></el-date-picker>
					<el-button v-if="editStatus" @click="submitEdit" type="primary" round size="mini" style="margin-left: 20px;">确认修改</el-button>
				</div>
			</div>
		</div>
		<div v-if="orderInfo.status==1" style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap;">
			<el-button v-if="orderInfo.pay_status==1" class="btn" type="primary" @click="startOrder">开电计时</el-button>
			<el-button v-if="orderInfo.pay_status==1" class="btn" type="info" @click="editTime">时间修改</el-button>
			<el-button v-if="orderInfo.pay_status==1" class="btn" type="success">提前结束</el-button>
			<el-button v-if="orderInfo.pay_status==1" class="btn" type="primary">更换包间</el-button>
			<el-button class="btn" type="danger">取消订单</el-button>
			<el-button v-if="orderInfo.pay_status==1" class="btn" type="danger">订单退款</el-button>
		</div>
	</div>
</template>

<script>
	
	export default {
		name:'orderAction',
		props:['orderInfo'],
		data(){
			return {
				editStatus : false
			}
		},
		methods:{
			stopOrder(){
				let that = this;
				this.$confirm('是否确认结束订单?', '提示', {
					confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
				}).then(() => {
					that.stopAction();
				}).catch(() => {
				});	
			},
			startOrder(){
				let that = this;
				this.$confirm('是否确认开启开电?', '提示', {
					confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
				}).then(() => {
					that.startAction();
				}).catch(() => {
				});	
			},
			stopAction(){
				const loading = this.$loading({
						lock: true,
						text: '结束中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Order/adminStopOrder", {order_id:that.orderInfo.order_id}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 1)
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(res=>{
					loading.close();
				});
			},
			startAction(){
				const loading = this.$loading({
						lock: true,
						text: '开电中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Order/adminStartOrder", {order_id:that.orderInfo.order_id}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 1)
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(res=>{
					loading.close();
				});
			},
			cancelEdit(){
				this.editStatus = false;
			},
			submitEdit(){
				const loading = this.$loading({
						lock: true,
						text: '修改中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Order/editOrderTime", {order_id:that.orderInfo.order_id, start_time:that.orderInfo.start_time, ended_time:that.orderInfo.ended_time}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 1)
					{
						that.editStatus = false;
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			editTime(){
				this.editStatus = true;
			}
		}
	}
</script>

<style>
</style>