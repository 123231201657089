<template>
	
	<div style="width: 100vw; height: 100vh; background-color: black;">
		
		<div class="layer1"></div>
		<div class="layer2"></div>
		<div class="layer3"></div>
		<div class="layer4"></div>
		<div class="layer5"></div>
		
	</div>
</template>

<script>
	export default {
		name:'roleList'
	}
</script>

<style lang="scss">
	@function getShadows($n){
		$shadows: '#{random(100)}vw #{random(100)}vh #fff';
		@for $i from 2 through $n {
			$shadows: '#{$shadows},#{random(100)}vw #{random(100)}vh #fff';
		}
		@return unquote($shadows);
	}
	
	@keyframes moveup{
		to{
			transform: translateY(-100vh);
		}
	}
	$duration: 1000s;
	$count: 1000;
	@for $i  from 1 through 5 {
		.layer#{$i} {
			$duration: floor($duration / 2);
			$count: floor($count / 2);
			position: fixed;
			$size: #{$i}px;
			width: $size;
			height: $size;
			background-color: white;
			border-radius: 50%;
			box-shadow:getShadows($count);
			animation: moveup $duration linear infinite;
			&::after {
				content:'';
				position: fixed;
				width: inherit;
				height: inherit;
				left: 0;
				top: 100vh;
				border-radius: inherit;
				box-shadow: inherit;
			}
		}
	}  
	
	
</style>