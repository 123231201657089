<template>
	<div id="app">
		美团
	</div>
</template>

<script>
	export default {
		name:'eWelink',
		data(){
			return {
				
			}
		}
	}
</script>

<style scoped>
</style>