<style scoped>
	.row {
		display: flex;
		flex-direction: row;
		height: 50px;
		line-height: 50px;
		font-size: 14px;
	}
	.ckey {
		margin-left: 180px;
		width: 80px;
		text-align: right;
	}
	.btn {
		margin-bottom: 10px;
	}
</style>

<template>
	<div id="app">
		<div style="display: flex; flex-direction: column; margin-bottom: 30px;">
			<div class="row">
				<div class="ckey">门店：</div>
				<div class="cvalue">{{storeInfo.store_name}}</div>
			</div>
			<div class="row">
				<div class="ckey">包间：</div>
				<div class="cvalue">{{roomInfo.room_name}}</div>
			</div>
			<div class="row">
				<div class="ckey">包间时长：</div>
				<div class="cvalue" style="display: flex; flex-direction: row; align-items: center;">
					<el-radio-group v-model="hour" size="mini" @input="hourChange">
					      <el-radio-button label="2">2小时</el-radio-button>
					      <el-radio-button label="4">4小时</el-radio-button>
					      <el-radio-button label="5">5小时</el-radio-button>
					      <el-radio-button label="8">8小时</el-radio-button>
					    </el-radio-group>
				</div>
			</div>
			<div class="row">
				<div class="ckey">价格：</div>
				<div class="cvalue" style="display: flex; flex-direction: row; align-items: center;">
					￥{{fee}}
				</div>
			</div>
			<div class="row">
				<div class="ckey">开始时间：</div>
				<div class="cvalue" style="display: flex; flex-direction: row; align-items: center;">
					<el-date-picker v-model="start_time" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" @change="timeChange"></el-date-picker>
				</div>
			</div>
			<div class="row">
				<div class="ckey">结束时间：</div>
				<div class="cvalue" style="display: flex; flex-direction: row; align-items: center;">
					<el-date-picker v-model="ended_time" type="datetime" placeholder="" disabled value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm"></el-date-picker>
				</div>
			</div>
		</div>
		<div  style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap;">
			<el-button class="btn" type="primary" style="width: 300px;" @click="submit">创建订单</el-button>
		</div>
	</div>
</template>

<script>
	
	export default {
		name:'createOrder',
		props:['roomInfo', 'storeInfo'],
		data(){
			return {
				fee:"0.00",
				editStatus : false,
				start_time:'2023-10-23 09:00',
				ended_time:'',
				hour:"4"
			}
		},
		created() {
			this.start_time = this.getCurrentTime();
			this.calc();
		},
		mounted() {
			console.log("mounted");
			// this.start_time = this.getCurrentTime();
			// this.calc();
		},
		methods:{
			submit(){
				let dataField = new Object();
				dataField.room_id = this.roomInfo.room_id;
				dataField.hour = this.hour;
				dataField.start_time = this.start_time;
				
				let that = this;
				const loading = this.$loading({
						lock: true,
						text: '创建中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				that.$api.post("/Order/createAdminOrder", dataField).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 1)
					{
						that.$message({
							 message: '创建成功~',
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			getCurrentTime() {
				let current = new Date();
				let year = current.getFullYear();
				let month = current.getMonth() + 1;
				let day = current.getDate();
				let hours = current.getHours();
				let minutes = current.getMinutes();
				let seconds = current.getSeconds();
				if(month < 10) month = '0' + month;
				if(day < 10) day = '0' + day;

				if(minutes < 30) 
				{
					minutes = 30;
				}else{
					minutes = 0;
					hours += 1;
				}
				if(hours < 10) hours = '0' + hours;
				if(minutes < 10) minutes = '0' + minutes;

				return  `${year}-${month}-${day} ${hours}:${minutes}`;
			},
			timeChange(e){
				this.calc();
			},
			hourChange(e){
				this.calc();
			},
			calc(){
				let current = new Date(this.start_time);
				let dateTime = new Date(current.getTime() + 1000*60*60*this.hour);

				let year = dateTime.getFullYear();
				let month = dateTime.getMonth() + 1;
				let day = dateTime.getDate();
				let hours = dateTime.getHours();
				let minutes = dateTime.getMinutes();
				
				if(month < 10) month = '0' + month;
				if(day < 10) day = '0' + day;
				if(hours < 10) month = '0' + month;
				if(minutes < 10) day = '0' + day;
				
				this.fee = this.hour * this.roomInfo.hour_fee;
				
				this.ended_time = `${year}-${month}-${day} ${hours}:${minutes}`;
			}
		}
	}
</script>

<style>
</style>