<template>
	<div class="main">
		<div class="ruler_box">
			<div class="ruler_slot_box" :style="{'background-color': styleConf.order_color}">
				<div class="ruler_slot" v-for="(item,index) in 24" :key="index">
					<div :class="{ 'ruler_slot_item_3' : index % 2 == 0 , 'ruler_slot_item_6' : index % 2 == 1}" v-if="index != 23" :key="index"></div>
				</div>
			</div>
			<div class="ruler_unselect_box">
				<div style="position:relative;" v-for="(item, index) in oneDateList.dateList" :key="index">
					<div class="ruler_unselect_item" :style="{'background-color': (item.status == 99) ? styleConf.un_order_color : styleConf.order_color, left: item.css_pos + '%',width: item.css_width +'%', top:'-'+ 7*index +'px'}"></div>
				</div>
			</div>
			<div class="ruler_num_box">
				<div :class="{ 'ruler_num':item!=0 && item!=24 , 'ruler_num0':item==0, 'ruler_num24':item==24 }" v-if="item%2==0" v-for="(item,index) in n024" :key="index">{{item}}</div>
			</div>
		</div>
		<div class="footer_line">
			<div class="f_n">{{oneDateList.dateTag}}</div>
			<div class="c_item" style="margin-left: 10px;">
				<div class="c_icon mIconfont" :style="{'color':styleConf.order_color}">■</div>
				<div class="c_name">可预定</div>
			</div>
			<div class="c_item" style="margin-left: 10px;">
				<div class="c_icon mIconfont" :style="{'color':styleConf.un_order_color}">■</div>
				<div class="c_name">不可预定</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:"timeRuler",
		props:{
			oneDateList:{
				type: Object,
				required: true
			},
			styleConf:{
				type:Object,
				required: true
			}
		},
		data() {
			return {
				n024:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
			};
		},
		// beforeMount() {
		// 	let that = this;
		// 	this.dateList.forEach((item,index)=>{
		// 		let start_min = Number(item.start_time.substr(0,2))*60 + Number(item.start_time.substr(item.start_time.length-2,item.start_time.length));
		// 		let ended_min = Number(item.ended_time.substr(0,2))*60 + Number(item.ended_time.substr(item.ended_time.length-2,item.ended_time.length));
		// 		let min_width = ended_min - start_min;
		// 		let css_pos = ((start_min/1440).toFixed(2)*100);
		// 		let css_width = ((min_width/1440).toFixed(2)*100);
		// 		that.dateList[index].css_pos = css_pos;
		// 		that.dateList[index].css_width = css_width;
		// 	});
		// 	console.log(this.dateList);
		// }
	}
</script>

<style>
	.main {
		width: 100%;
		height: 80px;
	}
	.ruler_box {
		width: 100%;
		height: 20px;
		display: flex;
		flex-direction: column;
		position: relative; top: 0; left: 0;
	}
	.ruler_slot_box {
		width: 100%;
		height: 20upx;
		border-radius: 10upx;
		display: flex;
		flex-direction: row;
		background-color: #CCCCCC;
	/* 	background-image: linear-gradient(to left, #3399FF,#0066ff); */
	}
	.ruler_slot {
		height: 20upx;
		width: 4.16%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-end;
	}
	.ruler_slot_item_3 {
		z-index: 98;
		height: 3px; width: 1px; background-color: #CCCCCC;
	}
	.ruler_slot_item_6 {
		z-index: 98;
		height: 6px; width: 1px; background-color: #CCCCCC;
	}
	.ruler_unselect_box {
		position: absolute;
		left: 0; top: 0;
		width: 100%; height: 20upx; border-radius: 10upx;
		overflow: hidden; 
		/* opacity: 0.9; */
	}
	.ruler_unselect_item {
		height: 7px;
		background-color: #3399FF;
		position: relative;
		left: 0%;
	}
	.ruler_num_box {
		width: 100%; display: flex; flex-direction: row;
		font-size: 10px;
		height: 20upx;
		color: #666666;
	}
	.ruler_num {
		width: 8.33%; text-align: center;
	}
	.ruler_num0 {
		width: 4.17%; text-align: left;
	}
	.ruler_num24 {
		width: 4.17%; text-align: right;
	}
	.footer_line {
		width: 100%;
		height: 30upx;
		line-height: 30upx;
		display: flex;
		flex-direction: row;
		font-size: 10upx;
	}
	.f_n {
		font-size: 10px;
		color: #222222;
	}
	.c_item {
		display: flex;
		flex-direction: row;
	}
	.c_icon {
		font-size: 20px;
		line-height: 10px;
		font-weight: bold;
	}
	.c_name {
		color: #999999;
		font-size: 10px;
	}
</style>