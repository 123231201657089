<template>
</template>

<script>
	export default {
		name:'PermissionList'
	}
</script>

<style>
</style>