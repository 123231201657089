<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>订单管理</el-breadcrumb-item>
			  <el-breadcrumb-item>订单列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<!-- <div style="height: 40px; line-height: 40px;">用户列表</div> -->
				<el-form :inline="true" class="demo-form-inline">
					
					<el-form-item label="" style="width: 120px;">
						<el-select @change="storeChange" v-model="store_id" placeholder="所有门店" clearable>
							<el-option label="所有门店" value=""></el-option>
							<el-option label="中坝店" value="1"></el-option>
							<el-option label="壹方里店" value="2"></el-option>
						</el-select>
					</el-form-item>
					
					
					
					<el-form-item label="" style="width: 120px;">
						<el-select @change="paywayChange" v-model="payway" placeholder="所有订单" clearable>
							<el-option label="所有订单" value=""></el-option>
							<el-option label="微信支付" value="wxpay"></el-option>
							<el-option label="美团验券" value="group"></el-option>
							<el-option label="后台下单" value="admin"></el-option>
							<el-option label="未付款" value="unpay"></el-option>
						</el-select>
					</el-form-item>
					
					
					<el-form-item label="">
						<el-input v-model="keyword" placeholder="手机、订单号"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
						
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="order_id" label="订单号" width="100"  align="center">
				</el-table-column>
				
				<el-table-column prop="store_name" label="门店" width="220"  align="center">
				</el-table-column>
				
				<el-table-column prop="room_name" label="包间" width="160"  align="center">
				</el-table-column>
				
				<el-table-column prop="phone" label="手机号" width="140"  align="center">
				</el-table-column>
				
				<el-table-column prop="start_time" label="开始时间" width="100"  align="center">
				</el-table-column>
				
				<el-table-column prop="ended_time" label="结束时间" align="center" width="100">
				</el-table-column>
				
				<el-table-column prop="price" label="价格" align="center" width="130">
					<template slot-scope="scope">
						{{(scope.row.price/100).toFixed(2)}}
					</template>
				</el-table-column>
				
				<el-table-column prop="price" label="付款方式" align="center" width="130">
					<template slot-scope="scope">
						<div v-if="scope.row.payway=='group'" style="color: darkcyan;">美团团购</div>
						<div v-if="scope.row.payway=='wxpay'" style="color: red;">微信支付</div>
						<div v-if="scope.row.payway=='admin'" style="color: orange;">后台下单</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="pay_status" label="付款" align="center"  width="120">
					<template slot-scope="scope">
						<el-button v-if="scope.row.pay_status==0" circle>否</el-button>
						<el-button v-if="scope.row.pay_status!=0" type="primary" circle>是</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="status" label="状态"  align="center"  width="120">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0" circle>否</el-button>
						<el-button v-if="scope.row.status==1" type="primary" circle>进</el-button>
						<el-button v-if="scope.row.status==88" type="danger" circle>完</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="clear_status" label="保洁" align="center"  width="120">
					<template slot-scope="scope">
						<el-button v-if="scope.row.clear_status==1" circle>待</el-button>
						<el-button v-if="scope.row.clear_status==88" type="danger" circle>完</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="create_time" label="下单时间" align="center" width="130">
				</el-table-column>
				
				<el-table-column fixed="right" label="操作" width="220" align="center">
					<template slot-scope="scope">
						<el-button v-if="scope.row.pay_status==0" @click="deleteOrder(scope.row, scope.$index)" type="warning" size="mini">删除订单</el-button>
						<el-button v-if="scope.row.pay_status==1" @click="actionOrderFunc(scope.row)" type="primary" size="mini">订单操作</el-button>
						<el-button type="danger"  size="mini" >订单信息</el-button>
						
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog :title="actionOrderTitle" :visible.sync="actionOrderView" width="700px" @close="actionOrderViewClose">
			<orderAction :orderInfo="actionOrderInfo"></orderAction>
		</el-dialog>
	</div>
</template>

<script>
	import orderAction from './orderAction.vue';
	
	export default {
		name:'List',
		components:{
			orderAction
		},
		data(){
			return {
				/* 操作订单控制参数 */
				actionOrderView:false,
				actionOrderTitle:'订单操作',
				actionOrderInfo:{},
				/* 搜索参数 */
				payway:'',
				store_id:'',
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: []
			}
		},
		created() {
			this.getData();
		},
		methods:{
			actionOrderViewClose(){
				this.actionOrderInfo = {};
			},
			deleteOrder(row, index){
				let that = this;
				this.$confirm('是否确认删除此订单?', '提示', {
					confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
				}).then(() => {
					that.deleteOrderAction(row, index);
				}).catch(() => {
				});	
			},
			deleteOrderAction(row, index){
				const loading = this.$loading({
						lock: true,
						text: '结束中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Order/deleteOrder", {order_id:row.order_id}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 1)
					{
						that.tableData.splice(index, 1);
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			actionOrderFunc(e){
				this.actionOrderInfo = e;
				this.actionOrderTitle = e.room_name + "       " + e.store_name + " ";
				this.actionOrderView = true;
			},
			paywayChange(e){
				this.payway = e;
				this.getData();
			},
			storeChange(e){
				this.store_id = e;
				this.getData(1);
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/Order/OrderList", {page:page, store_id:that.store_id, payway:that.payway, keyword:that.keyword}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			
			onSubmit(){
				this.getData();
			}
		}
	}
</script>