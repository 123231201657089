<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>用户管理</el-breadcrumb-item>
			  <el-breadcrumb-item>用户列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<!-- <div style="height: 40px; line-height: 40px;">用户列表</div> -->
				<el-form :inline="true" :model="searchForm" class="demo-form-inline">
					<el-form-item label="" style="width: 120px;">
						<el-select v-model="searchForm.personnel" placeholder="所有人" clearable>
							<el-option label="所有人" value="all"></el-option>
							<el-option label="管理权限" value="manager"></el-option>
							<el-option label="保洁权限" value="cleaner"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="searchForm.keyword" placeholder="手机、昵称、备注"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
						
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="uid" label="uid" width="100"  align="center">
				</el-table-column>
				
				<el-table-column prop="nickname" label="昵称" width="200"  align="center">
				</el-table-column>
				
				<el-table-column prop="phone" label="电话" align="center" width="200">
				</el-table-column>
				
				<el-table-column prop="register_time" label="注册时间" align="center" width="180">
				</el-table-column>
				
				<el-table-column prop="is_clear" label="保洁权限" align="center"  width="120">
					<template slot-scope="scope">
						<el-button v-if="scope.row.is_clear==0" circle>否</el-button>
						<el-button v-if="scope.row.is_clear!=0" type="primary" circle>是</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="rule" label="管理权限"  align="center"  width="120">
					<template slot-scope="scope">
						<el-button v-if="scope.row.rule==0" circle>否</el-button>
						<el-button v-if="scope.row.rule!=0" type="primary" circle>是</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="remark" label="备注" align="center">
				</el-table-column>
				
				<el-table-column fixed="right" label="操作" width="200" align="center">
					<template slot-scope="scope">
						<el-button @click="handleClick(scope.row)" type="warning" size="mini">查看</el-button>
						<el-button type="danger"  size="mini" >编辑</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name:'List',
		data(){
			return {
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				searchForm:{
					personnel:"",
					keyword:""
				},
				tableData: []
			}
		},
		created() {
			this.getData(1);
		},
		methods:{
			refresh(){
				this.getData(1);
				this.tableKey +=1;
			},
			getData(page){
				let that = this;
				that.loading = true;
				that.$api.post("/User/UserList", {page:page, personnel:that.searchForm.personnel, keyword:that.searchForm.keyword}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			handleClick(e){
			},
			onSubmit(){
				this.getData(1);
			}
		}
	}
</script>