<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; justify-content: space-between; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<div style="display: flex; flex-direction: row; align-items: center;">
				<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
				  <el-breadcrumb-item>门店列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<el-button type="danger" style="margin-right: 20px;">创建门店</el-button>
		</div>
		<div class="body_content">
			<div style="background-color: white; padding: 10px; border-radius: 10px; ">
				<div>门店图片</div>
				<div>门店名称</div>
				<div>门店地址</div>
				<div>门店状态</div>
				<div>门店地址</div>
			</div>
		</div>
	</div>
</template>

<script>
	import doubleTimeRuler from "@/components/other/doubleTimeRuler";
	import createOrder from "@/components/Order/createOrder";
	
	export default {
		name:'storeList',
		components:{
			doubleTimeRuler,
			createOrder
		},
		data(){
			return {

			}
		},
		created() {
		},
		methods:{
			
		}
	}
</script>

<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	.body_content {
		padding: 10px;
	}

</style>