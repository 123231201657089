<template>
</template>

<script>
	export default {
		name:'managerList'
	}
</script>

<style>
</style>